import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./homepage/Header";
import Footer from "./homepage/Footer";
import HeroSection from "./homepage/HeroSection";
import FeatureSection from "./homepage/FeatureSection";
import HowItWorksSection from "./homepage/HowItWorksSection";
import CTASection from "./homepage/CTASection";
import ScreenshotsSection from "./homepage/ScreenshotsSection";
import VideoDemoSection from "./homepage/VideoDemoSection";
import FAQSection from "./homepage/FAQSection";
import ExtensionPrivacyPolicy from "./homepage/ExtensionPrivacyPolicy";

function HomePage() {
  return (
    <div className="bg-zinc-900 min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow">
        <HeroSection />
        <FeatureSection />
        <ScreenshotsSection />
        <HowItWorksSection />
        <VideoDemoSection />
        <CTASection />
        <FAQSection />
      </main>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/extension-privacy-policy" element={<ExtensionPrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
