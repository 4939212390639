import React from "react";

const ExtensionPrivacyPolicy = () => (
  <div className="bg-zinc-900 min-h-screen text-zinc-100 py-12 px-4">
    <div className="max-w-3xl mx-auto">
      <h1 className="text-4xl font-bold mb-8">Browser Extension Privacy Policy</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
        <p className="mb-4">
          This Privacy Policy describes how the Pensaccio Browser Extension ("the Extension") collects, uses, and
          handles your data. Our extension is designed with privacy in mind, operating only when explicitly activated by
          you.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Permissions Required</h2>
        <p className="mb-4">The Extension requires the following browser permissions:</p>
        <ul className="list-disc list-inside mb-4 ml-4">
          <li>Active Tab - to access the current tab's content when you choose to save it</li>
          <li>Storage - to store your preferences and extension settings locally</li>
          <li>Scripting - to extract content from the current page when saving</li>
          <li>Tabs - to handle tab-related operations when saving content</li>
          <li>Context Menus - to provide right-click menu options for saving content</li>
        </ul>
        <p className="mb-4">
          These permissions are essential for the Extension's core functionality and are only used when you actively
          engage with the Extension.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Data Collection</h2>
        <p className="mb-4">The Extension only collects data when you explicitly activate it:</p>
        <ul className="list-disc list-inside mb-4 ml-4">
          <li>When you click the extension icon or use the context menu: current page URL and title</li>
          <li>When you choose to save a page: selected content or full page content</li>
        </ul>
        <p className="mb-4 font-semibold">Important: The Extension does NOT:</p>
        <ul className="list-disc list-inside mb-4 ml-4">
          <li>Track your browsing history</li>
          <li>Monitor your activity on other websites</li>
          <li>Collect any data without your explicit action</li>
          <li>Store any personal data locally except for your preferences</li>
          <li>Access tabs other than the one you're actively working with</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Data Processing and Storage</h2>
        <p className="mb-4">When you save content using the Extension:</p>
        <ul className="list-disc list-inside mb-4 ml-4">
          <li>The content is sent securely to Pensaccio servers</li>
          <li>Processing occurs within your personal account space</li>
          <li>Data is stored according to our main service's privacy policy</li>
          <li>No data is stored locally by the Extension itself</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Your Control</h2>
        <p className="mb-4">You maintain full control over the Extension:</p>
        <ul className="list-disc list-inside mb-4 ml-4">
          <li>Enable/disable the Extension at any time</li>
          <li>Remove the Extension completely from your browser</li>
          <li>Access and delete your saved content through your Pensaccio account</li>
          <li>Request complete deletion of your account and associated data</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Updates to Privacy Policy</h2>
        <p className="mb-4">
          We may update this Privacy Policy as we improve the Extension. Significant changes will be notified through
          the Extension interface and/or email if you have provided one.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Contact</h2>
        <p className="mb-4">
          For privacy concerns or questions about the Extension, contact us at:
          <br />
          <a href="mailto:extension@pensaccio.com" className="text-purple-400 hover:text-purple-300">
            extension@pensaccio.com
          </a>
        </p>
      </section>

      <footer className="text-sm text-zinc-400">Last updated: {new Date().toLocaleDateString()}</footer>
    </div>
  </div>
);

export default ExtensionPrivacyPolicy;
